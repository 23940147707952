import React, { useState, useEffect } from 'react'
import { PositionFetchAPI } from '../../api/Position';
import { GroupsFechtAPI } from '../../api/Groups'
import { Button, Modal, Row, Col } from 'react-bootstrap';
import stand from '../../assets/img/vending/stand.png'
import ModalSetLocker from './ModalSetLocker';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { MachinesFetchAPI } from '../../api/Machines';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { VendingFetchAPI } from '../../api/LocalRequest/Vending'
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import empty from '../../assets/img/vending/empty.png'
import Swal from 'sweetalert2';

import ModalVendingKeyboardControl from './ModalVendingKeyboardControl';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css'; // Asegúrate de importar los estilos



function VendingStock({ setShowModalStock, showModalStock, setEventHandlerMenu, temperature, humidity, eventHandlerMenu, setStatusScreen, setAtivityTimer }) {
    let token = localStorage.getItem("x-access-token");
    let business = JSON.parse(localStorage.getItem('business_vending'));
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'));
    let machine = ''
    let positions_Local = localStorage.getItem('positions')
    const [positions, setPositions] = useState([]);
    const [buttonActive, setButtonActive] = useState(false)

    if (localStorage.getItem('machine_vending') != '' && localStorage.getItem('machine_vending') != undefined && localStorage.getItem('machine_vending') != null) {
        machine = JSON.parse(localStorage.getItem('machine_vending'));
    }

    const [disablePosition, setDisablePosition] = useState([])
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [showModalAssign, setShowModalAssign] = useState(false);
    const [showModalKeyboardControl, setShowModalKeyboardControl] = useState(false)
    const [showModalTemp, setShowModalTemp] = useState(false);
    const [allProductsBranchoffice, setAllProductsBranchoffice] = useState([])
    const [productSelect, setProductSelect] = useState({ group: "" });
    const [eventHandlerModalAmount, setEventHandlerModalAmount] = useState(0);
    const [tempConfig, setTempConfig] = useState({ tempOn: 0, tempOff: 0 });
    const [resultPosition, setResultPosition] = useState([]);
    const [temporalItem, setTemporalItem] = useState([]);
    //positions valid
    if (positions_Local != undefined && positions_Local != "" & positions_Local != null) {
        positions_Local = JSON.parse(localStorage.getItem('positions'))

    }

    function actualizar() {
        window.location.reload();
    }


    //get products and the position of the machine
    const getPosition = async (dataScan) => {

        try {
            //Request local vending get position scan
            //let data_result = { funcion: "QUERYINIT", datos: [{ X: "0", Y: "0" }] }
            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
            //const response_result = await VendingFetchAPI.operation(response_machine.data.data.android_ip, data_result);
            //let data_position = JSON.stringify(response_result.data.data);

            //let array_position_vending_scan = JSON.parse(data_position.slice(25, (data_position.length - 2)))
            let array_position_vending_scan = dataScan.filter(item => item != 0)
            //Resquest get all position
            const response = await GroupsFechtAPI.getGroupsByMachine(machine.id, token);
            console.log(response);
            let postions_response = response.data.groups


            //avalible or disable position of the vending
            let temp_position = new Array;
            let temp_position_disables = new Array;
            for (let i = 0; i < array_position_vending_scan.length; i++) {
                for (let f = 0; f < postions_response.length; f++) {
                    if ((i + 1) == postions_response[f].positionX) {
                        if (postions_response[f].positionY <= array_position_vending_scan[i]) {//Avalible
                            postions_response[f].status_position = true
                            temp_position.push(postions_response[f])
                        } else if (postions_response[f].positionY > array_position_vending_scan[i]) {//Disable
                            postions_response[f].status_position = false

                            let detail = {
                                "group_id": null,
                                "position_id": postions_response[f].position_id,
                                "amount": 0
                            }
                            temp_position_disables.push(detail)
                            temp_position.push(postions_response[f])
                        }
                    }
                }
            }

            console.log(temp_position_disables)

            const sortedTempPosition = [...temp_position].sort((a, b) => b.positionX - a.positionX);
            setPositions(temp_position.reverse())
            //setPositions(temp_position)
            localStorage.setItem('positions', JSON.stringify(array_position_vending_scan))
            cleanDisblePosition(temp_position_disables)

        } catch (err) {
            console.log(err);
        }
    }

    //get products and the position of the machine
    const getPosition_consult = async () => {
        console.log("entre a consuta de informacion ya guardada")
        if (positions_Local.length > 0) {

            try {

                let array_position_vending_scan = positions_Local

                //Resquest get all position
                const response = await GroupsFechtAPI.getGroupsByMachine(machine.id, token);
                console.log(response);
                let postions_response = response.data.groups


                //avalible or disable position of the vending
                let temp_position = new Array;
                let temp_position_disables = new Array;
                for (let i = 0; i < array_position_vending_scan.length; i++) {
                    for (let f = 0; f < postions_response.length; f++) {
                        if ((i + 1) == postions_response[f].positionX) {
                            if (postions_response[f].positionY <= array_position_vending_scan[i]) {//Avalible
                                postions_response[f].status_position = true
                                temp_position.push(postions_response[f])
                            } else if (postions_response[f].positionY > array_position_vending_scan[i]) {//Disable
                                postions_response[f].status_position = false

                                let detail = {
                                    "group_id": null,
                                    "position_id": postions_response[f].position_id,
                                    "amount": 0
                                }
                                temp_position_disables.push(detail)
                                temp_position.push(postions_response[f])
                            }
                        }
                    }
                }

                console.log(temp_position)

                const sortedTempPosition = [...temp_position].sort((a, b) => b.positionX - a.positionX);
                setPositions(temp_position.reverse())
                //setPositions(temp_position)
                localStorage.setItem('positions', JSON.stringify(array_position_vending_scan))
                cleanDisblePosition(temp_position_disables)

            } catch (err) {
                console.log(err);
            }

        } else {
            ErrorAlert("", "Debe escanear las posiciones", "warning")
        }
    }

    //function get groups by branch office
    const getAllGroupsBranchOffice = async () => {
        try {
            const response = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice.id, token)
            setAllProductsBranchoffice(response.data.data)
            setTemporalItem(response.data.data)
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }

    //scan the position of the  vending machine
    const syncscanVending = async () => {
        LoadingAlert("Escaneando posiciones", "Espere un momento por favor...")
        //let data = { funcion: "SYNCSCAN", datos: [{ X: "0", Y: "0" }] }
        let data = { "nboxid": 1 }
        try {

            /*const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
            const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, data);
            console.log(response);*/
            const resultScan = await RequestLocalFetchAPI.setscandata(data)
            let dataScan = resultScan.data.Result

            console.log(dataScan);



            if (dataScan.length > 0) {
                ErrorAlert("Posiciones actualizadas con exito.", "Escaneo completado", "success")
                getPosition(dataScan);
                setEventHandlerMenu(1);
            } else {
                ErrorAlert("Ocurrio un error actualizando las posiciones.", "Escaneo no se completo", "error")
            }

        } catch (err) {
            ErrorAlert("Verifique que el servidor android este funcionando.", "Error de comunicacion", "error")
            console.log(err);
        }
    }

    //get temperature setting of the vending machine
    const querytemp = async () => {
        setShowModalTemp(true)
        //let data = { funcion: "QUERYTEMP", datos: [{ X: "0", Y: "0" }] }
        let data = { "nboxid": 1 }
        try {
            /* const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
             const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, data)
             let data_Response = JSON.parse(response.data.data.RespuestaTemperatira)*/
            const responseGetTemp = await RequestLocalFetchAPI.gettempset(data)
            let data_Response = responseGetTemp.data.Result
            setTempConfig({ tempOn: data_Response[1], tempOff: data_Response[2]})

            setEventHandlerModalAmount(1);
        } catch (err) {
            console.log(err);
        }
    }

    //update setting temperature of the vending machine
    const updateSettingTemperature = async () => {
        LoadingAlert()
        //let data = { funcion: "SETTEMPERATURA", on: tempConfig.tempOn, off: tempConfig.tempOff }
        let data = {
            "nboxid": 1,
            "on": tempConfig.tempOn,
            "off": tempConfig.tempOff
        }

        try {
            /*const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
            const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, data);
            console.log(response);*/

            const responseSetTemp = await RequestLocalFetchAPI.setTempCold(data)
            let status = responseSetTemp.data.Result

            if (status == "99") {
                ErrorAlert("La temperatura se actualizo con exito.", "Temperatura actualizada", "success")
                setShowModalTemp(false)
            } else {
                ErrorAlert("Ocurrio un error.", "Temperatura no se actualizo", "error")
            }

        } catch (err) {
            console.log(err);
        }
    }

    //update and assign disposition of the position diabled
    const cleanDisblePosition = async (data) => {
        if (data.length > 0) {
            try {
                const response = await PositionFetchAPI.cleanPositionsDisabled(machine.id, data, token)
                console.log(response);

            } catch (err) {
                ErrorAlert("", "Las posiciones desabilitadas no fueron limpiadas", "error")
                //console.log("error");
                console.log(err);
            }
        }
    }

    //update and assign product in the position
    const assignProductAndUpdate = async (item) => {
        LoadingAlert()
        try {
            const response = await PositionFetchAPI.updatePositionToGroup(machine.id, item, token)
            console.log(response);
            //getPosition()
            getPosition_consult();
            console.log(response);
            setShowModalDetail(false);

            ErrorAlert("", "Producto actualizado", "success")
        } catch (err) {
            console.log(err);
        }
    }

    //Save product selected and view modal details
    const handleModalDetails = (position) => {

        setProductSelect(position);
        setShowModalDetail(true)
    }

    //decrease amount product select
    const handleDecrease = () => {
        if (productSelect.amount > 0) {
            productSelect.amount = (productSelect.amount - 1)
            setProductSelect(productSelect)
            setEventHandlerModalAmount(1);
        }
    }

    //Increase amount product select
    const handleIncrease = () => {
        productSelect.amount = (productSelect.amount + 1)
        setProductSelect(productSelect)
        setEventHandlerModalAmount(1);
    }

    //update product of the  position
    const handleupdateProductPosition = (item) => {
        item.position_id = productSelect.position_id;
        console.log(item);
        assignProductAndUpdate(item);
        setShowModalAssign(false);
        setShowModalDetail(false);

    }

    //update increase temperature 
    const handleIncreaseTemp = (type) => {
        tempConfig.tempOff = (tempConfig.tempOff + 1);
        tempConfig.tempOn = (tempConfig.tempOff + 4);
        setTempConfig(tempConfig)
        setEventHandlerModalAmount(1)
    }

    //update decrease temperature 
    const handleDecreaseTemp = (type) => {

        if (tempConfig.tempOff > 0) {
            tempConfig.tempOff = (tempConfig.tempOff - 1);
            tempConfig.tempOn = (tempConfig.tempOff + 4);
        }

        setTempConfig(tempConfig)
        setEventHandlerModalAmount(1)
    }

    //clear position of the item or group
    const clearPosition = () => {
        let data = {
            "tax": null,
            "taxtValue": null,
            "dTasaITBMSValue": null,
            "group_id": null,
            "branchoffice_id": null,
            "price": null,
            "name": null,
            "img": null,
            "status": null,
            "description": null,
            "amount": null,
            "group_type": null,
            "position_id": productSelect.position_id
        }
        handleupdateProductPosition(data)
    }

    //Function to logout 
    const signout = async () => {
        let machine_token = localStorage.getItem('x-access-token');
        let machine = JSON.parse(localStorage.getItem('machine_vending'));
        try {
            const response = await MachinesFetchAPI.updateMachineStatus(machine.code, false, machine_token);

            localStorage.clear();
            ErrorAlert("", "Se cerro la sesion", "success");
            console.log(response);
            reload()
        } catch (err) {
            ErrorAlert("", "Error de cierre de sesion", "error");
            console.log(err)
        }

    }

    //reload the page
    const reload = () => {
        window.location.reload()
    }

    //action dispese in the vending machine
    const dispense = async (X, Y) => {
        LoadingAlert("Despacho de prueba", "Espere un momento por favor...")
        //let json = { funcion: "DESPACHAR", datos: [{ X: X.toString(), Y: Y.toString() }] }
        let json = { "nboxid": 1, "x": X, "y": Y }
        try {
            /*const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
            const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, json)*/

            const responseDispense = await RequestLocalFetchAPI.salegoodspro(json)

            let status = responseDispense.data.Result
            console.log(responseDispense);

            if (status[0].shipresult == 0) {
                ErrorAlert("", "Despacho exitoso", "success")
            } else {
                ErrorAlert("", "Error de despacho", "error")
            }
        } catch (err) {
            ErrorAlert("", "Error de despacho", "error")
            console.log(err);
        }

    }


    //
    const turnOff = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Quieres apagar la maquina',
            text: "Si precionas si se pagara en un segundo.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si apagar',
            cancelButtonText: 'No, Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                swalWithBootstrapButtons.fire(
                    'APAGAANDO MAQUINA',
                    '....',
                    'success'
                );
                shutdownMachine()
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'NO APAGAR MAQUINA',
                    'No se apagara la maquina',
                    'info'
                )
            }
        })
    }

    const shutdownMachine = async () => {
        try {

            const response = await RequestLocalFetchAPI.postShutdownMachine();
            console.log(response)
        } catch (err) {
            console.log(err)
        }
    }



    //View position and product assign
    /*const viewPosition = () => {

        const reversedArray = positions


        const listPosition = reversedArray.map((position, index) => (
            <><div key={index} className="col-2 p-1">{position.status_position == true ? <>
                {position.group_id == null ? <>
                    <div className=" border-vending-secondary my-2 mx-1" onClick={() => (handleModalDetails(position))}>
                        <h5 className='text-center mt-4'> {position.positionX} - {position.positionY} </h5>
                        <h5 className=' fw-bold text-center mt-5'  >
                            <small className='name-stock-item'> Vacío</small>
                        </h5>
                       
                    </div>

                </> :
                    <>
                        <div className={position.amount == 0
                            ? " border-vending-danger my-2 mx-1 "
                            : (position.amount > 3
                                ? "border-vending-success my-2 mx-1 "
                                : " border-vending-warning my-2 mx-1 "
                            )}
                            onClick={() => (handleModalDetails(position))}
                        >

                            <h5 className='text-center mt-4'> {position.positionX} - {position.positionY} </h5>
                            <div className='mt-5'>
                                <h5 className=' fw-bold text-center'  >
                                    <small className='name-stock-item'> {position.name} </small>
                                </h5>
                                <h3 className='text-center mt-4'>Cantidad: {position.amount}</h3>
                            </div>



                        </div>
                   
                    </>}
            </> : <>
                <div className=" border border-4 shadow-sm rounded opacity-25 bg-dark text-white">
                    <h5 className='text-center mt-4'> {position.positionX} - {position.positionY} </h5>
                    <h5 className=' fw-bold text-center mt-5'  >
                        <small className='name-stock-item'> Inhabilitado</small>
                    </h5>
                   
                </div></>}</div></>
        ));
        return (<div className="">
            <div className="row">{listPosition}</div>
        </div>);
    }*/

//View position and product assign
const viewPosition = () => {

    const reversedArray = positions


    const listPosition = reversedArray.map((position) => (
        <>
        {position.positionY == 0?<></>:<>
        {position.status_position == true ? <>
            {position.group_id == null ? <>
                <div className=" border border-4 shadow-sm rounded opacity-25 bg-light" onClick={() => (handleModalDetails(position))}>
                    <h5 className='text-center mt-4'> {position.positionX} - {position.positionY} </h5>
                    <h5 className=' fw-bold text-center mt-5'  >
                        <small className='name-stock-item'> Vacío</small>
                    </h5>
                    {/* <div className=' border border-4 shadow-sm rounded opacity-25 bg-light'>
                        <h5 className=' fw-bold text-center'  >                              
                           <small className='name-stock-item opacity-25'> {position.name} </small>
                        </h5>
                    </div>  */}
                </div>

            </> :
                <>
                    <div className={position.amount == 0
                        ? " border shadow-sm   bg-empty-item "
                        : (position.amount > 3
                            ? "border shadow-sm   bg-optimum-item"
                            : " border shadow-sm   bg-warning-item"
                        )}
                        onClick={() => (handleModalDetails(position))}
                    >

                        <h5 className='text-center mt-4'> {position.positionX} - {position.positionY} </h5>
                        <div className='mt-5'>
                            <h5 className=' fw-bold text-center'  >
                                <small className='name-stock-item opacity-25'> {position.name} </small>
                            </h5>
                            <h3 className='text-center mt-4'>{position.amount}</h3>
                        </div>



                    </div>
                    {/* <div className='col-md-3'>
                <div className='border rounded m-2 p-2' >
                    <div className='d-flex alight-item-center justify-content-center'>
                    <img src={position.img} height="50px"></img></div>
                    <h3 className='text-center'>{position.name}</h3>
                    <div className='d-flex alight-item-center justify-content-center '>
                        <h3 className='text-center'>{position.amount}</h3>
                    </div>
                </div>
            </div> */}
                </>}
        </> : <>
            <div className=" border border-4 shadow-sm rounded opacity-25 bg-dark text-white">
                <h5 className='text-center mt-4'> {position.positionX} - {position.positionY} </h5>
                <h5 className=' fw-bold text-center mt-5'  >
                    <small className='name-stock-item'> Inhabilitado</small>
                </h5>
                {/* <div className=' border border-4 shadow-sm rounded opacity-25 bg-light'>
                        <h5 className=' fw-bold text-center'  >                              
                           <small className='name-stock-item opacity-25'> {position.name} </small>
                        </h5>
                    </div>  */}
            </div></>}</>}</>
    ));
    return (listPosition);
}

    //filter item assign
    const handlerFilter = (e) => {
        if (e.target.value == "") {
            setTemporalItem(allProductsBranchoffice)
            //allProductsBranchoffice
        } else {
            //let arrayFilter = allProductsBranchoffice.filter(item => JSON.stringify(item) == e.target.value)
            let arrayFilter = []
            allProductsBranchoffice.forEach(item => {
                let valueCondition = item.name.toLowerCase().includes(e.target.value.toLowerCase())
                if (valueCondition == true) {
                    arrayFilter.push(item)
                }
            });
            setTemporalItem(arrayFilter)
        }

    }

    //view Products of the branchoffice
    const viewProductBranchoffice = () => {

        const listProducts = temporalItem.map((item) => (
            <div className='col-6'>
                <div className='border-vending-secondary p-1 m-1' onClick={() => (handleupdateProductPosition(item))}>
                    <div className='d-flex alight-item-center justify-content-center'>
                        <img src={item.img} height="100px"></img>
                    </div>
                    <h3 className='text-center text-dark'>{item.name}</h3>
                </div>
            </div>
        ));

        return (<>{listProducts}</>)
    }

    useEffect(() => {
        (async () => {
            getPosition_consult();
            if (machine.machine_subtype_code == "VCG") {
                setButtonActive(true)
            }

        })()
    }, [eventHandlerMenu]);



    //reload component modal detail product select
    useEffect(() => {
        setEventHandlerModalAmount(0);
    }, [eventHandlerModalAmount]);

    return (
        <>


            {/* Stock Area showModalStock*/}
            <Modal
                show={showModalStock}
                fullscreen
                onHide={() => setShowModalStock(false)}>
                <Modal.Header>

                    <h2 className='title-modal-stock '>
                        <i class="uil uil-vector-square-alt"></i>
                        Área de Abastecimiento
                    </h2>




                </Modal.Header>
                <Modal.Body className='m-0 p-0 no-scroll '>



                    <main className=' p-0 m-0'>
                        <RenderIf isTrue={positions_Local == undefined || positions_Local == "" || positions_Local == null}>
                            <div className='d-flex justify-content-center align-items-center align-content-center align-self-center' onClick={() => (syncscanVending())} >
                                <div className='empty-stock text-center bg-light'>

                                    <i className="uil uil-refresh icon-button"></i>
                                    <p className='text-muted'>
                                        Debes escanear las posiciones
                                    </p>
                                </div>
                            </div>

                        </RenderIf>
                        {/*<div className="container-section no-scroll pb-5">
                            {viewPosition()}
                            <div className='py-5'>
                            </div>
                        </div>*/}

                        <div className="stock-container no-scroll h-100 ">
                            {viewPosition()}
                        </div>


                        <div className='d-flex justify-content-center bar-buttons   m-1  w-100 '>
                            <div className=' d-flex justify-content-between alight-item-center gap-1 p-1 border-vending-black bg-white '>
                                <button className=' border-vending-primary  btn-lg m-1 '
                                    //onClick={() => (syncscanVending())}
                                    onClick={() => (localStorage.removeItem('GroupsByMachine'), setShowModalStock(false), setEventHandlerMenu(1), setAtivityTimer(3))}>
                                    <i className="uil uil-arrow-left icon-button-setting-vending"></i>
                                </button>

                                {/* <button className=' border-vending-primary  btn-lg m-1' onClick={async () => (setShowModalKeyboardControl(true))}>
                                    <i class="uil uil-dialpad-alt icon-button-setting-vending"></i></button> */}

                                <RenderIf isTrue={buttonActive == true}>
                                    <button className='border-vending-primary btn-lg m-1' onClick={() => (querytemp())}>
                                        <i className="uil uil-temperature-plus icon-button-setting-vending"></i> </button>
                                </RenderIf>
                                <button className='border-vending-primary btn-lg m-1' onClick={async () => signout()}>
                                    <i className="uil uil-signout icon-button-setting-vending"></i></button>



                                <RenderIf isTrue={buttonActive == true}>
                                    <button className='border-vending-primary btn-lg m-1'
                                        onClick={() => (syncscanVending())}
                                    >
                                        <i className="uil uil-refresh icon-button-setting-vending"></i>

                                    </button>
                                </RenderIf>

                                {/*<ModalSetLocker></ModalSetLocker>*/}

                                <button className='border-vending-success btn-lg m-1' onClick={async () => (turnOff())}>
                                    <i className="uil uil-power icon-button-setting-vending"></i></button>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
            {/* Stock Area */}

            {/*Modal details position*/}
            <Modal
                show={showModalDetail}
                fullscreen
                onHide={() => setShowModalDetail(false)}
                aria-labelledby="example-custom-modal-styling-title">

                <div className='menu-vending-title '  >
                    <h4 className='text-center fw-bold'>
                        Modo Abastecimiento
                    </h4>

                </div>

                <Modal.Body className='m-0 p-0 position-relative'>
                    <RenderIf isTrue={productSelect.group_id != null}>

                        <main>
                            <div className='product-stand position-relative'>

                                <div class="d-flex align-items-center border-vending-black mx-2">
                                    <div class="flex-shrink-0">
                                        <img src={productSelect.img}
                                            className='foreground-image img-modal-product-stock-2 m-2 border-vending-black'
                                            alt="..." />
                                    </div>
                                    <div class="flex-grow-1 ms-3 text-dark">
                                        <h3 className='text-center modal-title-product'>
                                            {productSelect.name}
                                        </h3>
                                    </div>
                                </div>
                                {/* <div className="buttons-steps w-100">
                                    <div className='d-flex justify-content-between p-1 mx-1  '>
                                        <button className="  btn-menu-vending pulse" onClick={() => (afterProduct())}>
                                            <span className='text-center '>
                                                <i class="uil uil-angle-left-b"></i>
                                            </span>
                                        </button>
                                        <button className=" btn-menu-vending pulse" onClick={() => (nextProduct())}>
                                            <span className='text-centert'>
                                                <i class="uil uil-angle-right-b"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                            <div className=' mx-4 mb-4 mt-5'>
                                <div className='flex-colunm'>
                                    <div className=" border-vending-black pt-4 position-relative">


                                        <span className='px-1 number-counter pb-2'>
                                            <button className="round-button pulse border-vending-black" onClick={() => (handleDecrease())}>
                                                <span className="minus"></span>
                                            </button>
                                            <p>{productSelect.amount}</p>
                                            <button className="round-button pulse   border-vending-black" onClick={() => (handleIncrease())}>
                                                <span className="plus"></span>
                                            </button>
                                        </span>

                                        <div className='position-absolute top-0 start-50 text-center translate-middle w-75 border-vending-black bg-white'>
                                            <span className='title-count-stock  fw-bold'>Cantidad de cargas realizadas </span>
                                        </div>
                                    </div>
                                </div>


                                <RenderIf isTrue={productSelect.group_id != null}>
                                    <div className='row mt-3'>

                                        <div className="col-6">
                                            <button className='border-vending-primary px-4 fw-bold m-1 w-100' onClick={() => (assignProductAndUpdate(productSelect))}>
                                                <i className="uil uil-sync mx-3  icon-button-setting-vending "></i>
                                                <br />
                                                Actualizar
                                            </button>
                                        </div>
                                        <div className='col-6'>
                                            <RenderIf isTrue={productSelect.amount > 0}>
                                                <button className='border-vending-primary px-4 fw-bold m-1 w-100' onClick={() => (dispense(productSelect.positionX, productSelect.positionY))}>
                                                    <i className="uil uil-sign-out-alt  icon-button-setting-vending "></i>
                                                    <br />
                                                    Despachar
                                                </button>
                                            </RenderIf>
                                        </div>
                                        <div className='col-6'>
                                            <button className='border-vending-primary px-4 fw-bold m-1 w-100'
                                                onClick={() => (setShowModalAssign(true), getAllGroupsBranchOffice())}>
                                                <i className="uil uil-exchange mx-2 icon-button-setting-vending "></i>
                                                <br />
                                                Cambiar Producto
                                            </button>
                                        </div>

                                        <div className='col-6'>
                                            <button className='border-vending-danger px-4 m-1 fw-bold w-100'
                                                onClick={() => (clearPosition())} >
                                                <i className="uil uil-border-clear mx-2 icon-button-setting-vending"></i>
                                                <br />
                                                Limpiar Posición
                                            </button>
                                        </div>
                                    </div>
                                </RenderIf>

                            </div>
                        </main>
                    </RenderIf>

                    <RenderIf isTrue={productSelect.group_id == null}>
                        <div className='d-flex justify-content-center align-items-center' onClick={() => (setShowModalAssign(true), getAllGroupsBranchOffice())}>
                            <div className='empty-stock text-center'>
                                <i className="uil uil-bring-bottom "></i>
                                <p>
                                    No hay ningún producto asignado en esta  posición
                                </p>
                            </div>
                        </div>
                        <div className='d-flex alight-item-center justify-content-center m-2'>
                            <button className='border-vending-primary  mx-3 display-5 fw-bold px-3'
                                onClick={() => (setShowModalAssign(true), getAllGroupsBranchOffice())}>
                                Asignar producto
                            </button>
                        </div>
                    </RenderIf>
                    <div className='d-flex justify-content-start bar-returns   m-1   '>

                        <button className='border-vending-primary '
                            onClick={() => (setShowModalDetail(false))}
                        >
                            <i className="uil uil-arrow-left icon-button-setting-vending"></i>
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
            {/*End Modal details position*/}














            {/*Modal assign product to the position*/}
            <Modal
                show={showModalAssign}
                size='lg'
                onHide={() => setShowModalAssign(false)}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header>

                    <h1 className='text-center m-2'>Asignar producto</h1>


                </Modal.Header>
                <Modal.Body>
                    <main className='flex-container'>

                        <div className=" d-flex justify-content-center alight-items-center mb-2 ">
                            <div><h2 className='text-center fw-bold pt-2 pe-2'> <i className="uil uil-search-alt"></i></h2></div>
                            <div><input type="text" className="form-control input-vending-filter w-100"
                                placeholder='Buscar Producto' onChange={(e) => handlerFilter(e)} /></div>
                        </div>
                        <div className='container-section row'>
                            {viewProductBranchoffice()}
                        </div>
                        <div>
                        </div>
                    </main>

                </Modal.Body>
            </Modal>


            {/*Modification temp*/}
            <Modal
                show={showModalTemp}
                size='lg'
                onHide={() => setShowModalTemp(false)}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div>
                                <h3 className='text-center' >Temperatura actual</h3>
                                <h1 className='text-center'>{temperature}° C</h1>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div>
                                <h3 className='text-center' >Humedad actual</h3>
                                <h1 className='text-center'>{humidity}%</h1>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div className=' border rounded m-3 p-3'>
                            <h3 className='text-center'>Configuracion de temperatura</h3>
                            <div className='row'>
                                <div className='col-sm-4 d-flex  justify-content-center'>
                                    <Button onClick={() => (handleDecreaseTemp())} >-</Button>
                                </div>
                                <div className='col-sm-4 '>
                                    <h1 className='text-center'>{tempConfig.tempOff}° C</h1>
                                </div>
                                <div className='col-sm-4 d-flex alight-item-center justify-content-center'>
                                    <Button onClick={() => (handleIncreaseTemp())}>+</Button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='d-flex alight-item-center justify-content-center'>
                        <Button className='m-2' onClick={() => (setShowModalTemp(false), setStatusScreen(false))}>Volver</Button>
                        <Button className='m-2' onClick={async () => (updateSettingTemperature())}>Actualizar temperatura</Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/*keboard control vending*/}
            <ModalVendingKeyboardControl showModalKeyboardControl={showModalKeyboardControl} setShowModalKeyboardControl={setShowModalKeyboardControl}></ModalVendingKeyboardControl>
        </>
    )
}
export default VendingStock