import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import locker from './img/locker.png'
import ModalLockerSafe from './ModalLockerSafe'
import ModalUnlock from './ModalUnlock'



import Swal from 'sweetalert2';

function ModalLocker({contentSupport, setEventHandlerMenu, setAtivityTimer}) {
    let token = localStorage.getItem('x-access-token');
    let machine = JSON.parse(localStorage.getItem('machine_vending'));
    let business = localStorage.getItem('business_vending')
    let branchoffice = localStorage.getItem('branchoffice_vending')

    const [showModalLocker, setShowModalLocker] = useState(false)



    return (
        <>

            <div className='d-flex justify-content-center'><button className=' btn-menu-vending ' onClick={() => setShowModalLocker(true)}>
                <span className='position-relative '>
                    <i className="uil uil-keyhole-square "> Locker</i>
                </span>
            </button></div>
            <Modal
                show={showModalLocker}
                size='lg'
                centered
                onHide={() => setShowModalLocker(false)}
            >
                <Modal.Body>

                    <div className='d-flex alight-item-center justify-content-center' >
                        <section className='mx-2'>
                            <div className='d-flex justify-content-center m-0'>
                                
                            </div>

                            <article>
                                <div className='my-1'>
                                    <h2 className='text-center text-title-help fw-bold'><i className="uil uil-keyhole-square "> Locker</i></h2>
                                </div>

                                <div className=' vendindg-message text-primary bg-primary-subtle border-primary border p-1 rounded-2'>
                                    <span className='text-center fw-bold'>
                                        <i class="uil uil-info-circle mx-1"></i>
                                        Selecciona la accion a realizar
                                    </span>


                                </div>


                                <div className='kiosk-delivery_btn  d-flex alight-item-center justify-content-center' >
                                    <div className='kiosk-delivery_btns  mt-5 tracking-in-expand-forward-top d-flex alight-item-center justify-content-center'>

                                        <ModalLockerSafe contentSupport={contentSupport} setEventHandlerMenu={setEventHandlerMenu} setAtivityTimer={setAtivityTimer}></ModalLockerSafe>
                                        <ModalUnlock contentSupport={contentSupport} setEventHandlerMenu={setEventHandlerMenu} setAtivityTimer={setAtivityTimer}></ModalUnlock>

                                        
                                    </div>
                                </div>
                                <div>


                                    <div className='d-flex justify-content-between mt-5 my-2 rounded-3 '>
                                        <button className=' m-1 btn-return-vending  rounded-3 px-5 pulse'
                                            onClick={async () => (setShowModalLocker(false))} >
                                            <i className="uil uil-times-circle"></i>  Cerrar
                                        </button>

                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>

                </Modal.Body>


            </Modal>



        </>
    )
}

export default ModalLocker